// @flow

import React from "react";
import "./ErrorButton.css";

const ErrorButton = (props) => {
  const { textClassNames, playerText } = props;

  return (
    <>
      <div className="error-button">
        <span className="gelicon--alert" />
      </div>
      <span className={textClassNames}>{playerText}</span>
    </>
  );
};

export default ErrorButton;
