// @flow

const injectScript = (
  src: string,
  domTarget: HTMLElement,
  onLoad: Function = () => {},
  onError: Function = () => {}
) => {
  if (typeof domTarget === 'undefined') return;
  const elem = document.createElement('script');
  elem.src = src;
  elem.async = true;
  elem.type = 'text/javascript';
  domTarget.appendChild(elem);
  elem.onload = onLoad;
  elem.onerror = onError;
};

export default injectScript;
