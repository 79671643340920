// @flow
/* eslint-disable react/sort-comp */

import React from "react";
import loadBump, { DocElementPool } from "./loadBump";
import { isAdsDebug } from "../debug";

type Props = {
  href: ?string, // direct href to audio asset
  vPid: ?string, // version pid
  cPid?: string, // stats clip pid
  counterName: string,

  // wire up to parent
  onStateChanged: Function,
  setRef: Function,

  disableAdverts: boolean,
  statsDestination?: string,
  statsProducer?: string,
  externalPlugin: string,
  audioLabel: string,
  volumeControls?: boolean,
  branding?: "worklife" | "future" | "culture" | "earth" | "travel" | "",
};

declare var requirejs: Function;

// eslint-disable-next-line one-var
export const INIT = "init",
  IDLE = "idle",
  LOADING = "loading",
  PLAYING = "playing",
  PAUSED = "paused",
  ERROR = "error",
  PHASE_AD = "ad",
  PHASE_MAIN = "main";

export type State = {
  status: "idle" | "init" | "loading" | "playing" | "paused" | "error",
  phase?: "ad" | "main",
};

type SMPInstance = Object & {
  $PWAcat: ?string,
  pause: Function,
  paused: () => boolean,
  ended: () => boolean,
  seeking: () => boolean,
  play: Function,
  stop: Function,
  settings: (?Object) => Object,
  loadPlaylist: (Object, Object) => void,
  dispatchEvent: (string, any) => void,
};

const docElementPool = new DocElementPool("_smp_unused_");

class CreateSMPAudio extends React.PureComponent<Props, State> {
  // previously created players from later unmounted components go back here
  static playerPool: SMPInstance[] = [];

  static defaultProps = {
    vPid: undefined,
    cPid: undefined,
    autoplay: false,
    guidance: "",
    statsDestination: "",
    statsProducer: "",
    volumeControls: true,
    branding: "",
    setRef: () => {},
    onStateChanged: () => {},
  };

  // ref
  smpDiv: Element | Node;

  // initialisePlayer sets this
  mediaPlayer: SMPInstance;

  checkAdTagTimeout: ?TimeoutID = null;

  onAdErrorTimeout: ?TimeoutID = null;

  trackedAdTag: ?string = "";

  // need some state to work around ad plugin issues
  // NB: phase is deliberately set as late as possible to work around two issues
  //     1. the ad plugin assumes control of the player and signalling breaks
  //     2. Safari signals start of playback before it has finished long-buffering
  // see onTimeUpdate for more details
  state: State = {
    status: INIT,
    phase: undefined,
  };

  setStateAndNotify = (state: State) => {
    this.setState(state, () => {
      this.props.onStateChanged(this.state);
    });
  };

  init() {
    this.checkForGNLadsBeforePlayerInit()
      .then(this.initialisePlayer)
      .catch((err) => {
        // gnl ads errored, maybe it couldn't find the plugin or requirejs
        console.error(err);
        // initialise the player without ads
        this.initialisePlayer();
      });
  }

  /**
   * stops the player and ad plugin and clears out playlist
   * smp has no working destroy(), this is the closest we can get
   * call this before release()-ing a player to go back into the pool
   */
  deinit() {
    if (this.checkAdTagTimeout) clearTimeout(this.checkAdTagTimeout);
    if (this.onAdErrorTimeout) clearTimeout(this.onAdErrorTimeout);
    this.trackedAdTag = "";
    const smp = this.mediaPlayer;
    smp.pause();
    smp.stop();
    smp.dispatchEvent("bbc.smp.plugins.ads.event.updateAdTag", { adTag: "" });
    this.detach();
    // resets the ad plugin
    smp.loadPlaylist(this.getPlaylist(false, "--"), {});
    // console.log('* deinit player', smp.$PWAcat, smp.player.getId(), smp.player.uniqueId)
  }

  componentDidMount() {
    if (!this.constructor.Bump) this.constructor.Bump = loadBump();
    this.init();
  }

  // keyed by href this would be thrown out altogether if props.href changes
  componentDidUpdate(prevProps: Props) {
    const { href, vPid, disableAdverts, branding, externalPlugin } = this.props;

    // we're keyed by locator but left here for consistency
    const locator = href || vPid;
    const prevLocator = prevProps.href || prevProps.vPid;

    if (
      locator !== prevLocator ||
      disableAdverts !== prevProps.disableAdverts ||
      branding !== prevProps.branding ||
      externalPlugin !== prevProps.externalPlugin
    ) {
      if (this.mediaPlayer && this.smpDiv) {
        this.componentWillUnmount();
        this.init();
      }
    }
  }

  componentWillUnmount() {
    if (!this.smpDiv) return;
    if (!this.mediaPlayer) return;
    const smp = this.mediaPlayer;
    this.deinit();
    this.constructor.release(smp);
    // $FlowFixMe destroying instance
    delete this.mediaPlayer;
  }

  onInitialised = () =>
    this.setStateAndNotify({ status: IDLE, phase: undefined });

  // ignore this event until we're actually playing main media
  onPaused = () => {
    const { status, phase } = this.state;
    // if the user presses play and pause without the file ever starting we end up here
    if (phase === PHASE_AD || status === IDLE) return;
    this.setStateAndNotify({ status: PAUSED });
  };

  onPlaylistEnded = () =>
    this.setStateAndNotify({ status: IDLE, phase: undefined });

  onShowCta = () => this.setStateAndNotify({ status: IDLE, phase: undefined });

  onError = (ev: any) => {
    // there are 4 error severity types critical, error, warning and info. Only critical is non recoverable
    // https://confluence.dev.bbc.co.uk/display/mp/Error+Model
    if (ev.severity !== "critical") return;
    this.setStateAndNotify({ status: ERROR });
  };

  onPlaying = () => {
    // normally we'd use this to update status to playing only
    // but with ads we get a playing-but-not-playing event (.pausedatzero=true)
    // also see this.onTimeUpdate
    this.setStateAndNotify({
      status: this.state.phase === PHASE_MAIN ? PLAYING : LOADING,
    });
  };

  /**
   * this handler sets playing status once main media is actually playing
   * otherwise sets loading status
   * @param ev
   */
  onTimeUpdate = (ev: any) => {
    if (!ev.duration) return;
    const { status, phase } = this.state;

    if (phase === PHASE_AD) return; // ad plugin signals playback
    const isPlaying = status === PLAYING;

    const player = this.mediaPlayer;

    if (!isPlaying && !player.seeking()) {
      // IE11 sends timeupdate events outside of Play and Pause (without actually playing)
      // FF occasionally sends a main phase timeupdate event before an ad plays
      if (player.paused() || player.ended()) {
        // *
        console.info("timeupdate ignored");
        return;
      }
      const newState: State = { status: PLAYING };
      // if the ad plugin never loaded, we'd find ourselves here without a phase
      // if no phase, then set it to main the first time we signal playing
      // works around Safari signalling Play before a file has finished buffering
      if (!phase) newState.phase = PHASE_MAIN;
      this.setStateAndNotify(newState);
    }
  };

  onAdsPluginEvent = (e: any) => {
    switch (e.id) {
      // occasionally in IE, the ad plugin gets stuck on adManagerLoaded
      case "adRequest":
        this.setStateAndNotify({
          status: LOADING,
          phase: PHASE_AD,
        });
        break;
      case "adStarted":
        this.setStateAndNotify({
          status: PLAYING,
          phase: PHASE_AD,
        });
        break;
      case "adEnded":
        this.setStateAndNotify({
          status: LOADING,
          phase: PHASE_MAIN,
        });
        break;
      case "adError": {
        // @todo fix smp/plugin bug? then player controls itself
        if (e.code === 1005 || e.code === 1009 || e.code === 1010) {
          this.setState(
            {
              status: LOADING,
              phase: undefined,
            },
            () => {
              // likely we got e.code=1009 here because of empty ad
              // https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdError

              this.props.onStateChanged(this.state);
              const player = this.mediaPlayer;

              player.play(); // try to recover

              let timeUpdateFired = false;
              const timeupdateListener = () => {
                timeUpdateFired = true;
                player.unbind("timeupdate", timeupdateListener);
              };
              player.bind("timeupdate", timeupdateListener);

              this.onAdErrorTimeout = setTimeout(() => {
                if (!timeUpdateFired) {
                  player.unbind("timeupdate", timeupdateListener);
                  player.pause();
                  // reuse the timeout here
                  this.onAdErrorTimeout = setTimeout(() => {
                    player.play();
                  }, 50);
                }
              }, 1000);
            }
          );
        } else {
          // it broke. give up
          this.setStateAndNotify({
            status: IDLE,
            phase: undefined,
          });
          console.log("adError", e);
          // NB code 402 = timeout loading media file
          // it usually recovers by itself in this path
        }
        break;
      }
      default:
      // nop
    }
  };

  getNewAdTag(gnlAdverts: any) {
    // in case we're ever reinitialised between checks
    if (this.checkAdTagTimeout) clearTimeout(this.checkAdTagTimeout);

    this.trackedAdTag = undefined;

    if (!gnlAdverts) {
      return this.trackedAdTag; // nothing to do
    }

    const checkAdTag = () => {
      const oldAdTag = this.trackedAdTag;
      const adTag = gnlAdverts.getPrerollAdTagForAudio();
      if (this.mediaPlayer && oldAdTag !== adTag) {
        this.trackedAdTag = adTag;
        this.mediaPlayer.dispatchEvent(
          "bbc.smp.plugins.ads.event.updateAdTag",
          {
            adTag,
          }
        );
      }
    };

    checkAdTag(); // 1

    // let's check consuming code has had enough time to set up relevant details
    // and pass to bbcdotcom object

    this.checkAdTagTimeout = setTimeout(() => {
      checkAdTag(); // 2
      this.checkAdTagTimeout = setTimeout(checkAdTag, 2000); // 3
    }, 500);

    return this.trackedAdTag;
  }

  static GNLAdsPromise: Promise<any>;

  static Bump: Promise<any>;

  // this checks if ads are not disabled (this.props.disableAdverts)
  // and if they are enabled in global config (window.bbcdotcom.config.isAdsEnabled())
  // then resolves with an instance of bbcdotcom/av/emp/adverts or null
  checkForGNLadsBeforePlayerInit() {
    if (this.constructor.GNLAdsPromise) return this.constructor.GNLAdsPromise;

    // $FlowFixMe this version of flow gets really confused here
    this.constructor.GNLAdsPromise = new Promise((resolve, reject) => {
      const config = window.bbcdotcom && window.bbcdotcom.config;

      if (this.props.disableAdverts || !config || !config.isAvailable) {
        resolve(null);
        return;
      }

      config.isAvailable().then((available) => {
        if (available && config.isAdsEnabled()) {
          // requirejs is 'accidentally' getting bundled into the page via orbit
          // eslint-disable-next-line no-undef
          requirejs(["bbcdotcom/av/emp/adverts"], resolve, reject);
        } else {
          resolve(null);
        }
      });
    });
    return this.constructor.GNLAdsPromise;
  }

  /**
   * Creates a media player or pulls one from the pool of unused players
   * @param {boolean} withAds
   * @returns {Promise<SMPInstance>}
   */
  createMediaPlayer = (withAds: boolean = false): Promise<SMPInstance> => {
    const { externalPlugin, volumeControls } = this.props;
    // the allocator calls createFn if the player pool is empty
    const createFn = this.constructor.createPlayerFn(
      { externalPlugin, volumeControls },
      withAds,
      isAdsDebug()
    );
    return this.constructor.Bump.then(() =>
      this.constructor.alloc(externalPlugin, createFn)
    );
  };

  /**
   * Return a clean playlist object. Used to set/reset the player.
   * @param {boolean} withAdverts
   * @param {string?} overrideLabel or use audioLabel in props
   * @returns {Object}
   */
  getPlaylist(withAdverts: boolean, overrideLabel: ?string) {
    const { audioLabel, vPid, href } = this.props;
    const items = [
      {
        ...(href ? { href } : { versionID: vPid }),
        kind: "radioProgramme",
      },
    ];

    if (withAdverts) items.unshift({ kind: "advert" });

    return {
      title: overrideLabel || audioLabel,
      items,
    };
  }

  initialisePlayer = (gnlAdverts: any = null) => {
    const { cPid, counterName, statsDestination, statsProducer, branding } =
      this.props;

    const colours = {
      worklife: "#0052a1",
      future: "#002856",
      culture: "#472479",
      earth: "#0fbb56",
      travel: "#589e50",
    };

    const playerPromise = this.mediaPlayer
      ? Promise.resolve(this.mediaPlayer)
      : this.createMediaPlayer(!!gnlAdverts);

    playerPromise.then((mp) => {
      this.mediaPlayer = mp;
      this.smpDiv.appendChild(this.mediaPlayer.settings().container);

      const dynamicSettings = {
        counterName,
        ui: {
          ...mp.settings().ui,
          colour: colours[branding],
        },
        statsObject: {
          clipPID: cPid,
          producer: statsProducer,
          destination: statsDestination,
        },
      };

      // call this on every init, it keeps state and knows about scheduling
      this.attach();
      this.props.setRef(this.mediaPlayer);

      // mp.settings(dynamicSettings);
      const playerPlaylist = this.getPlaylist(!!gnlAdverts);
      mp.loadPlaylist(playerPlaylist, dynamicSettings);

      // if (window.dotcom) {
      //   window.dotcom.ads.getAdTag().then((adTag) => {
      //     mp.dispatchEvent("bbc.smp.plugins.ads.event.updateAdTag", { adTag });
      //   });
      // } else {
      //   const adTag = this.getNewAdTag(gnlAdverts);
      //   mp.dispatchEvent("bbc.smp.plugins.ads.event.updateAdTag", { adTag });
      // }

      return mp;
    });
  };

  attach() {
    const mp = this.mediaPlayer;
    mp.bind("pause", this.onPaused);
    mp.bind("playing", this.onPlaying);
    mp.bind("playlistEnded", this.onPlaylistEnded);
    mp.bind("showCta", this.onShowCta);
    mp.bind("timeupdate", this.onTimeUpdate);
    mp.bind("initialised", this.onInitialised);
    mp.bind("adsPlugin", this.onAdsPluginEvent);
    mp.bind("error", this.onError);
  }

  detach() {
    const mp = this.mediaPlayer;
    if (!mp) return; // called before MP was created
    mp.unbind("pause", this.onPaused);
    mp.unbind("playing", this.onPlaying);
    mp.unbind("playlistEnded", this.onPlaylistEnded);
    mp.unbind("showCta", this.onShowCta);
    mp.unbind("adsPlugin", this.onAdsPluginEvent);
    mp.unbind("initialised", this.onInitialised);
    mp.unbind("timeupdate", this.onTimeUpdate);
    mp.unbind("error", this.onError);
  }

  ref = (el: any) => {
    this.smpDiv = el;
  };

  render() {
    return <div ref={this.ref} key={this.props.href || this.props.vPid} />;
  }

  /**
   * put SMP instance back into the pool for reuse
   * @param {SMPInstance} player
   */
  static release(player: SMPInstance) {
    // console.log('* releasing player', player.$PWAcat, player.player.getId(), player.player.uniqueId)
    docElementPool.releaseEl(player.settings().container);
    this.prototype.constructor.playerPool.push(player);
  }

  /**
   * Get SMP instance from the pool of unused players or create a new one via createFn
   * @param {string} vertical
   * @param {function():SMPInstance} createFn
   * @returns {SMPInstance}
   */
  static alloc(vertical: string = "", createFn: () => SMPInstance) {
    const pool = this.prototype.constructor.playerPool;
    // try to find a player from the same "category"
    // normally this would be a vertical but
    // it splits by external plugin (because that loads per-vertical CSS)
    // and plugins aren't destroyed
    const idx = pool.indexOf(pool.find((smp) => smp.$PWAcat === vertical));

    let mp: ?SMPInstance = idx > -1 ? pool.splice(idx, 1).pop() : null;

    // eslint-disable-next-line no-unused-expressions
    // ;!mp ? console.log('* alloc NEW player', vertical)
    //   : console.log('* alloc player', mp.$PWAcat, mp.player.getId(), mp.player.uniqueId)

    if (mp) return mp;
    mp = createFn();
    mp.$PWAcat = vertical;
    return mp;
  }

  /**
   * @param {{ externalPlugin: string, volumeControls: boolean }} props
   * @param {boolean} withAds
   * @param {boolean} debugAds
   * @returns {function(): SMPInstance}
   */
  static createPlayerFn(
    props: Object,
    withAds: boolean,
    debugAds: boolean
  ): () => SMPInstance {
    return () => {
      const { externalPlugin, volumeControls } = props;

      const plugin = {
        html: externalPlugin,
      };

      const playerSettings = {
        product: "news",
        uiClass: "testing",
        continuousPlay: false,
        responsive: true,
        superResponsive: true,
        volume: 0.2,
        ui: {
          controls: {
            mode: "default",
            speed: false,
            volumeSlider: volumeControls,
          },
          locale: {
            lang: "pt",
          },
          colour: "#000", // cta
          foreColour: "#fff",
          baseColour: "#000",
          colourOnHighlightColour: "#fff", // highlight
          colourOnBaseColour: "#fff",
          responsive: true,
          continuousPlay: false,
        },
        plugins: { toLoad: [plugin] },
        waitOnPluginLoad: true, // WWVERTICAL-8471,
      };

      if (withAds) {
        // add the ads plugin to the config
        const adPlugin = {
          html: "name:dfpAds.js",
          swf: "name:dfpAds.swf",
          data: {
            name: "AdsPluginParameters",
            data: {
              adTag: "",
              debug: debugAds,
              setupCompanionAds: {},
            },
          },
        };

        playerSettings.plugins = {
          toLoad: [plugin, adPlugin],
        };
      }

      const mp = window.embeddedMedia.api.player(
        document.createElement("div"),
        playerSettings
      );
      mp.load();
      // logAllSMPEvents(mp);
      return mp;
    };
  }
}

export default CreateSMPAudio;
