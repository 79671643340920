// @flow

import React from "react";
import buildModifier from "../../helpers/buildModifier/buildModifier";
import classNames from "classnames";
import "./PreviousMediaButton.css";

type Props = {
  onClick?: Function,
  colour?: "white" | "",
};

const PreviousMediaButton = (props: Props) => {
  const { onClick, colour } = props;

  const classes: string = classNames({
    "previous-media-button__icon": true,
    [buildModifier("previous-media-button__icon", colour)]: colour,
  });

  return (
    <button
      onClick={onClick}
      className="previous-media-button"
      aria-label="Anterior"
    >
      <span
        className={classes}
        dangerouslySetInnerHTML={{
          __html:
            '<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" fill={colour} /><path fillRule="evenodd" clipRule="evenodd" d="M14 -0.000213623L1.5 7.99979L14 15.9998V11.9203L7.3645 7.99979L14 4.07979V-0.000213623Z" fill={colour} /></svg>',
        }}
      />
    </button>
  );
};

PreviousMediaButton.defaultProps = {
  isInlineVideo: false,
  isInlineAudio: false,
  colour: "white",
  onClick: () => {},
  hexColourOverride: "",
};

export default PreviousMediaButton;
