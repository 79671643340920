// @flow

import React from "react";
import classNames from "classnames";
import "./Spinner.css";

export const FUTURE = "future";
export const CULTURE = "culture";
export const WORKLIFE = "worklife";
export const TRAVEL = "travel";

const Spinner = ({ branding, color, audio }) => {
  const rootClass = "spinner";

  return (
    <span
      className={classNames({
        [`${rootClass}`]: branding === null,
        [`${rootClass}--${CULTURE}`]: branding === CULTURE,
        [`${rootClass}--${TRAVEL}`]: branding === TRAVEL,
        [`${rootClass}--${FUTURE}`]: branding === FUTURE,
        [`${rootClass}--${WORKLIFE}`]: branding === WORKLIFE,
        [`${rootClass}--white`]: color === "white",
        [`${rootClass}--audio`]: audio,
      })}
    >
      <svg
        className={`${rootClass}__image`}
        viewBox="0 0 32 32"
        id="gel-icon-loading"
        width="100%"
        height="100%"
      >
        <path d="M29.8 8l-3.5 2c1 1.8 1.6 3.8 1.6 6 0 6.6-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4V0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16c0-2.9-.8-5.6-2.2-8z" />
      </svg>
    </span>
  );
};

Spinner.defaultProps = {
  branding: null,
  color: "",
  audio: false,
};

export default Spinner;
