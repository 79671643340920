// @flow

export function isAdsDebug(): boolean {
  return (
    /[?|&]ads-debug/.test(window.location.href)
    ||
    document.cookie.indexOf('ads-debug=') !== -1
  )
}


const logSMPEvent = (ev: { id: ?string, type: ?string }) => {
  console.log(ev.id || ev.type, ev)
}

type SMP = {
  bind: Function
}

export function logAllSMPEvents(mediaPlayer: SMP, fn: Function = logSMPEvent) {
  ;['initialised', 'error', 'playing', 'adsPlugin', 'pause', 'ended', 'playlistLoading', 'playlistLoaded',
    'playlistEnded', 'playlistStopped', 'playlistsChanged', 'timeupdate', 'significanttimeupdate', 'volumechange',
    'waiting', 'seeking', 'canplay', 'canplaythrough', 'mediaItemChanged', 'loadedmetadata',
    'pluginLoaded', 'pluginLoadFailed', 'pluginInitialisationFailed', 'pluginUnLoaded', 'popoutrequest',
    'uiinfo', 'guidanceUpdated', 'guidanceClosed', 'guidanceShown', 'markerStart', 'markerEnd',
    'canPauseChange', 'picInPicStarted', 'picInPicStopped', 'showCta', 'userPlay', 'needkey',
    'continuousPlayChange', 'showPlaybackSettingsButton', 'hidePlaybackSettingsButton', 'focusPlaybackSettingsButton',
    'playbackSettingsPanelOpen', 'playbackSettingsPanelClosed', 'previousRequested', 'nextRequested',
    'bbc.smp.plugins.ads.event.updateAdTag'
  ].forEach(ev => mediaPlayer.bind(ev, fn))
}

export function diffScan(o: Object) { // scan for changes in react class component. call from e.g. render()
  // skip first render
  if ('prevState' in o) {
    const { prevProps, prevState } = o
    Object.keys(o.props).forEach(prop => {
      if (o.props[prop] !== prevProps[prop]) {
        console.log(`prop: ${prop} differs new/old`, o.props[prop], prevProps[prop])
      }
    })
    Object.keys(o.state).forEach(key => {
      if (o.state[key] !== prevState[key]) {
        console.log(`state: ${key} differs new/old`, o.state[key], prevState[key])
      }
    })
  }

  /* eslint-disable */
  o.prevProps = o.props
  o.prevState = o.state
  /* eslint-enable */
}
