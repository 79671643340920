// @flow

import React from "react";
import classNames from "classnames";
import buildModifier from "../helpers/buildModifier/buildModifier";
import "./StyledLine.css";

type Props = {
  // eslint-disable-next-line flowtype/space-after-type-colon
  colour?:
    | "worklife"
    | "future"
    | "culture"
    | "earth"
    | "travel"
    | "white"
    | "dark-grey"
    | "grey"
    | "light-grey"
    | "",
  size?: "xs-small" | "small" | "medium" | "large" | "",
  heightSize?: "large" | "small" | "",
  hexColourOverride?: string,
};

const StyledLine = (props: Props) => {
  const { colour, size, heightSize, hexColourOverride } = props;
  const componentClasses: string = classNames({
    "styled-line": true,
    [buildModifier("styled-line", colour)]: colour && !hexColourOverride,
    [buildModifier("styled-line", size)]: size,
    [buildModifier("styled-line--height", heightSize)]: heightSize,
  });

  return <div className={componentClasses} />;
};

StyledLine.defaultProps = {
  colour: "culture",
  size: "",
  heightSize: "",
  hexColourOverride: "",
};

export default StyledLine;
