import injectScript from "../../helpers/injectScript/injectScript.js";

// Or use stage bump:
// //emp.stage.bbci.co.uk/emp/bump-4/bump-4.js

const loadBump = () =>
  new Promise((resolve, reject) => {
    if (typeof window.embeddedMedia === "undefined") {
      injectScript(
        "//emp.bbci.co.uk/emp/bump-4/bump-4.js",
        document.head,
        resolve,
        reject
      );
    } else {
      resolve();
    }
  });

export default loadBump;

/**
 * Creates a hidden div in which to place unused SMP containers
 * Prevents errors from SMP not being able to find them in the document
 * when player instances are in CreateSMPAudio's pool and not in use
 */
export class DocElementPool {
  constructor(elementId = "_unused_els_") {
    this.id = elementId;
    this.inited = false;
  }

  releaseEl(el) {
    if (!this.inited) this.init();
    // even though we keep a reference to a container
    // this pool has to exist in the document
    const poolEl =
      typeof document !== "undefined" ? document.getElementById(this.id) : null;
    if (poolEl) {
      poolEl.appendChild(el);
    } else {
      console.error("could not find pool div", el);
      if (el.parentNode) el.parentNode.removeChild(el);
    }
  }

  init() {
    if (this.inited) {
      throw new Error("DocElementPool.init() but already inited");
    }

    if (typeof document === "undefined" || !document) {
      throw new Error("DocElementPool.init() but no document");
    }

    if (document.getElementById(this.id)) {
      console.error("DocElementPool.init but element already exists");
    }

    const poolEl = document.createElement("DIV");
    poolEl.id = this.id;
    const rules = {
      display: "none",
      visibility: "hidden",
      height: "0",
      width: "0",
      position: "absolute",
      left: "-31337px",
    };
    Object.keys(rules).forEach((prop) => {
      poolEl.style.setProperty(prop, rules[prop]);
    });
    document.body.appendChild(poolEl);
    this.inited = true;
  }
}
