// @flow

import React from "react";
import classNames from "classnames";
import buildModifier from "../../helpers/buildModifier/buildModifier";
import Spinner from "../Spinner/Spinner";
import "./PlayButton.css";

type Props = {
  colour?:
    | "worklife"
    | "future"
    | "culture"
    | "earth"
    | "travel"
    | "white"
    | "black"
    | "",
  playVideo?: Function,
  mobile?: boolean,
  hexColourOverride?: string,
  isInlineVideo?: boolean,
  isInlineAudio?: boolean,
  loading?: boolean,
  textClassNames: string,
  playerText: string,
};

const PlayButton = (props: Props) => {
  const {
    colour,
    playVideo,
    mobile,
    hexColourOverride,
    isInlineVideo,
    isInlineAudio,
    loading,
    textClassNames,
    playerText,
  } = props;

  const classes: string = classNames({
    "play-button": true,
    "play-button--desktop": !mobile,
    [buildModifier("play-button", colour)]: colour,
    "play-button--background-hover":
      hexColourOverride !== "" && hexColourOverride,
  });

  const inlineClasses = classNames({
    "play-button__inline-audio": isInlineAudio,
    "play-button__inline-video": isInlineVideo,
    [buildModifier("play-button__inline-video", colour)]: colour,
  });

  const iconClasses = classNames({
    "gelicon--listen": isInlineAudio,
    "gelicon--play": !isInlineAudio,
  });

  if (playVideo) {
    return (
      <>
        <button
          onClick={playVideo}
          className={isInlineVideo || isInlineAudio ? inlineClasses : classes}
          aria-label="Play article audio"
        >
          {loading ? (
            <Spinner color="white" audio={isInlineAudio} />
          ) : (
            <>
              <span className="play-button__icon">
                <span
                  className="play-button__hidden_element"
                  aria-hidden="true"
                >
                  Play article audio
                </span>
                <span className={iconClasses} />
              </span>
              <span className={textClassNames}>{playerText}</span>
            </>
          )}
        </button>
      </>
    );
  }

  return (
    <div className={isInlineVideo ? inlineClasses : classes}>
      <span className={iconClasses} />
    </div>
  );
};

PlayButton.defaultProps = {
  isInlineVideo: false,
  isInlineAudio: false,
  colour: "",
  playVideo: null,
  mobile: true,
  hexColourOverride: "",
  loading: false,
};

export default PlayButton;
