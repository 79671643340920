// @flow

const buildModifier = (rootClassName: string, modifier: any): string => {
  if (typeof rootClassName === 'string' && typeof modifier === 'string') {
    return `${rootClassName}--${modifier}`;
  }
  return '';
};

export default buildModifier;
