// @flow
/* eslint-disable react/sort-comp */

import React from "react";
import classNames from "classnames";
import PreviousMediaButton from "../Buttons/PreviousMediaButton/PreviousMediaButton";
import PlayButton from "../Buttons/PlayButton/PlayButton";
import ErrorButton from "../Buttons/ErrorButton/ErrorButton";
import StyledLine from "../StyledLine/StyledLine";
import CreateSMPAudio, {
  INIT,
  IDLE,
  LOADING,
  PLAYING,
  PAUSED,
  ERROR,
  PHASE_AD,
  type State as SMPAudioState,
} from "../helpers/CreateSMP/CreateSMPAudio";
import "./InlineAudioPlayer.css";

type AtiModuleTrackingData = $Shape<{
  "data-bbc-container": string,
  "data-bbc-title": string,
  "data-bbc-metadata": string,
  "data-bbc-result": string,
  "data-bbc-client-routed"?: boolean,
}>;

type Props = {
  vPid: string,
  cPid: string,
  guidance: string,
  disableAdverts: boolean,
  statsDestination: string,
  statsProducer: string,
  counterName: string,
  href?: string,
  externalPlugin: string,
  atiTrackingAttributes: AtiModuleTrackingData,
  audioLabel: string,
  mobile?: boolean,
  offline?: boolean,
  branding?: "worklife" | "future" | "culture" | "earth" | "travel" | "",
};

type State = SMPAudioState & {
  showExplain: boolean,
  forceExplain: boolean,
  stoppedTimes: number, // used as key to reset the player
};

const playerText = (status, offline) => {
  switch (true) {
    case offline:
      return "Parece que você está sem conexão de internet";
    case status === INIT:
      return "";
    case status === ERROR:
      return "Por favor, tente novamente mais tarde";
    default:
      return "Ouvir esta reportagem";
  }
};

type SMPPlayer = Object & { pause: Function, play: Function, stop: Function };

class InlineAudioPlayer extends React.Component<Props, State> {
  static defaultProps = {
    vPid: "",
    href: "",
    mobile: true,
    offline: false,
    branding: "",
  };

  state: State = {
    status: INIT,
    phase: undefined, // one of PHASE_AD, PHASE_MAIN
    showExplain: true,
    forceExplain: true,
    stoppedTimes: 0,
  };

  player: SMPPlayer;

  playOneAudioOnly = (ev: any) => {
    // flow doesn't like CustomEvent here
    if (ev.detail === this.props.href) return; // we emitted this
    // console.log('playOneAudioOnly', ev.detail, this.props.href)
    const { status, phase } = this.state;
    const isPlaying = status === PLAYING;
    const isLoading = status === LOADING;

    if (phase === PHASE_AD) {
      this.forceStopSMP();
    } else if (isPlaying || isLoading) {
      this.player.pause();
    }
  };

  forceStopSMP() {
    // re-keys SMP and throws it out
    const { stoppedTimes } = this.state;
    this.setState({ stoppedTimes: stoppedTimes + 1 });
  }

  handleCTAButton = () => {
    if (!this.player) {
      // we should never find ourselves here
      console.error("handleCTAButton but no .player?");
      return;
    }

    const { status, phase } = this.state;

    if (phase === PHASE_AD && status === LOADING) {
      // on IE an ad may get stuck Loading forever
      this.forceStopSMP();
      return;
    }
    if (phase === PHASE_AD) return;

    const smp = this.player;
    const isPlaying = !smp.paused() && !smp.ended() && !smp.seeking();
    if (status === LOADING) {
      smp.pause();
      smp.stop();
      this.setState({ forceExplain: false });
    } else if (isPlaying) {
      smp.pause();
    } else {
      smp.play(true);
      // this is done twice - first when the user clicks the play button
      // and again when the audio actually starts playing
      document.dispatchEvent(
        new CustomEvent("audioPlaying", { detail: this.props.vPid })
      );
    }
  };

  componentDidMount() {
    document.addEventListener("audioPlaying", this.playOneAudioOnly);
  }

  componentWillUnmount() {
    document.removeEventListener("audioPlaying", this.playOneAudioOnly);
  }

  handleRewindButton = () => {
    if (!this.player) {
      console.error("handleRewind but no .player?");
      return;
    }
    this.player.currentTime(0);
  };

  dropDownClicked = () => {
    const { showExplain } = this.state;
    this.setState({
      showExplain: !showExplain,
    });
  };

  setRef = (player: SMPPlayer) => {
    this.player = player;
  };

  onSMPStateChanged = ({ status, phase }: SMPAudioState) => {
    if (status === PLAYING && status !== this.state.status) {
      // when we're actually playing, dispatch our event once more
      // see handleCTAButton
      document.dispatchEvent(
        new CustomEvent("audioPlaying", { detail: this.props.href })
      );
    }

    const newState: Object = { status, phase };
    if (status === PLAYING) {
      newState.forceExplain = true;
    } else if (status === IDLE) {
      newState.forceExplain = false;
    }

    this.setState(newState);
  };

  render() {
    const {
      vPid,
      cPid,
      guidance,
      counterName,
      disableAdverts,
      statsDestination,
      statsProducer,
      href,
      externalPlugin,
      audioLabel,
      mobile,
      offline,
      branding,
    } = this.props;
    const { status, phase, showExplain, forceExplain, stoppedTimes } =
      this.state;

    // ideally this matches the player's Playing state
    // but inside SMP and sometimes inside Safari that's not the case
    const isInitialisingPlayer = status === INIT;
    const isLoading = status === LOADING;
    const isAdPhase = phase === PHASE_AD;
    const isUIPlaying = [PLAYING, PAUSED].indexOf(status) >= 0;
    const canPlay = status !== ERROR && !offline;
    const hidePlayer = !isUIPlaying || (isLoading && isAdPhase);
    const shouldShowCta = hidePlayer;
    const showRewind = !isAdPhase;

    const audioPanelClasses: string = classNames({
      "inline-audio-player__container": true,
      "inline-audio-player__hidden": hidePlayer,
    });

    const arrowButtonClasses: string = classNames({
      "inline-audio-player__arrow-button": true,
      "inline-audio-player__arrow-button__open": showExplain || forceExplain,
      "inline-audio-player__arrow-button__hide": true,
    });

    const collapsibleContainerClasses: string = classNames({
      "collapsible-container": true,
      "collapsible-container__hide": !(showExplain || forceExplain),
    });

    const headingTextClasses: string = classNames({
      "inline-audio-player__text": true,
      "inline-audio-player__text--offline": !canPlay,
      "b-font-family-serif": true,
    });

    const ctaHolderClasses: string = classNames({
      "inline-audio-player__cta-holder": true,
    });

    const ctaContainerClasses: string = classNames({
      "inline-audio-player__cta-container": true,
      initialising: isInitialisingPlayer,
    });

    const locator = href || vPid;

    // if stopped during ad phase,
    // jettison the component (resets SMP)
    // see onSMPStateChanged
    const smpKey = `${locator}-${stoppedTimes}`;

    return (
      <div className="inline-audio-player">
        {shouldShowCta && (
          <div className={ctaHolderClasses}>
            <div
              {...this.props.atiTrackingAttributes}
              className={ctaContainerClasses}
            >
              {canPlay ? (
                <PlayButton
                  playVideo={this.handleCTAButton}
                  loading={isLoading || isInitialisingPlayer}
                  textClassNames={headingTextClasses}
                  playerText={playerText(status, offline)}
                  isInlineAudio
                />
              ) : (
                <ErrorButton
                  textClassNames={headingTextClasses}
                  playerText={playerText(status, offline)}
                />
              )}
            </div>
            <button
              disabled={forceExplain}
              className={arrowButtonClasses}
              onClick={this.dropDownClicked}
            >
              <svg
                width="10"
                height="8"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 0L7.76219 0L5 4.49262L2.23719 0L0 0L5 8L10 0Z"
                  fill="#4D4D49"
                />
              </svg>
            </button>
          </div>
        )}
        <div className={audioPanelClasses}>
          {showRewind && (
            <PreviousMediaButton
              onClick={this.handleRewindButton}
              mobile
              isInlineAudio
            />
          )}
          <div className="inline-audio-player__smp-container">
            <CreateSMPAudio
              key={smpKey}
              setRef={this.setRef}
              onStateChanged={this.onSMPStateChanged}
              vPid={vPid}
              cPid={cPid}
              href={href}
              counterName={counterName}
              guidance={guidance}
              disableAdverts={disableAdverts}
              statsDestination={statsDestination}
              statsProducer={statsProducer}
              externalPlugin={externalPlugin}
              volumeControls={!mobile}
              audioLabel={audioLabel}
              branding={branding}
            />
          </div>
        </div>
        <div className={collapsibleContainerClasses}>
          Esta reportagem é lida por uma voz artificial gerada por computador.
          Pode conter erros de pronúncia, emoção e tom.
        </div>
        <div className="inline-audio-player__line">
          <StyledLine size="large" colour="dark-grey" />
        </div>
      </div>
    );
  }
}

export default InlineAudioPlayer;
